import React, { useState } from "react";
import classes from "./AccountModal.module.css";
import { Modal, Tabs } from "@mantine/core";
import { mdiAccountDetails, mdiCog, mdiHomeAccount, mdiShieldLock } from "@mdi/js";
import Icon from "@mdi/react";
import { AccessManagementUserBase } from "types/auth";
import InitialsIcon from "components/_Library/InitialsIcon/InitialsIcon";
import DetailsTab from "./Tabs/DetailsTab";
import SettingsTab from "./Tabs/SettingsTab";
import PrivacyTab from "./Tabs/PrivacyTab";
import OverviewTab from "./Tabs/OverviewTab";

interface OwnProps {
    closeModal: () => void;
    user: AccessManagementUserBase | undefined
}

type AccountModalProps = OwnProps;

const AccountModal: React.FC<AccountModalProps> = ({ closeModal, user }) => {

    const closeModalWithAnalytics = () => {
        closeModal();
    };

    const [selectedTab, setSelectedTab] = useState<string>("overview")
    
    return (
        <div>
            <Modal
                opened={true}
                onClose={closeModalWithAnalytics}
                size={"90rem"}
                closeButtonProps={{
                    size: "2rem"
                }}
                classNames={{
                    header: classes.ModalHeader,
                    content: classes.Modal
                }}
            >
                <div onClick={(event) => {
                    event.stopPropagation();
                }}>
                    <Tabs
                        orientation={"vertical"}
                        value={selectedTab}
                        className={classes.TabMenu}
                        variant={"userPreferences"}
                    >
                        <Tabs.List>
                            <div className={classes.SettingsTitle}>
                                <InitialsIcon width={4} firstName={user?.first_name} lastName={user?.last_name}/>
                                <p className={classes.Title}>Settings</p>
                            </div>
                            <Tabs.Tab value={"overview"} icon={<Icon path={mdiHomeAccount} size={1.6}/>} onClick={() => setSelectedTab("overview")}>Overview</Tabs.Tab>
                            <Tabs.Tab value={"details"} icon={<Icon path={mdiAccountDetails} size={1.6}/>} onClick={() => setSelectedTab("details")}>Details</Tabs.Tab>
                            <Tabs.Tab value={"settings"} icon={<Icon path={mdiCog} size={1.6}/>} onClick={() => setSelectedTab("settings")}>Settings</Tabs.Tab>
                            <Tabs.Tab value={"privacy"} icon={<Icon path={mdiShieldLock} size={1.6}/>} onClick={() => setSelectedTab("privacy")}>Privacy</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value={"overview"} pl={"lg"}>
                            <OverviewTab user={user} onQuickLinkSelection={(tab: string) => {
                                setSelectedTab(tab)
                            }}/>
                        </Tabs.Panel>

                        <Tabs.Panel value={"details"} pl={"lg"}>
                            <DetailsTab user={user}/>
                        </Tabs.Panel>

                        <Tabs.Panel value={"settings"} pl={"lg"}>
                            <SettingsTab user={user}/>
                        </Tabs.Panel>
    
                        <Tabs.Panel value={"privacy"} pl={"lg"}>
                            <PrivacyTab user={user}/>
                        </Tabs.Panel>
                    </Tabs>

                </div>
                
            </Modal>
        </div>
    );
};

export default AccountModal;