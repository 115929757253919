/**
 * Used to remove some of the boilerplate on the topmost component in the tree. Usually Report.
 */

import { ColorScheme, MantineProvider } from "@mantine/core";
import React, { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { getCssVar } from "utils/CSSHelpers";
import { NEW_THEME_COOKIE, getCookie } from "utils/Cookies";

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

const ThemeProvider: React.FC<Props> = ({ children }) => {
    const theme: string =
        useSelector(
            (state: RootState) => getStoreAtNamespaceKey(state, "user").theme,
        ) ||
        getCookie(NEW_THEME_COOKIE) ||
        "light";

    useEffect(() => {}, [theme]);

    return (
        <MantineProvider withCSSVariables
            theme={{
                colorScheme: theme as ColorScheme,
                defaultRadius: "md",
                primaryShade: 6,
                colors: {
                    dark: [
                        "var(--text-color)",
                        "var(--text-color)",
                        "var(--text-color-hi-cont)",
                        "var(--secondary-color-hi-cont)",
                        "var(--secondary-color-hi-cont)",
                        "var(--secondary-color-lo-cont)",
                        "var(--secondary-color)",
                        "var(--primary-color-hi-cont)",
                        "var(--primary-color)",
                        "var(--primary-color)",
                    ],
                    geoHighlight: [
                        "#fbf0fd",
                        "#efdef1",
                        "#ddbbdf",
                        "#ca95ce",
                        "#bb75bf",
                        "#b160b6",
                        "#ac56b2",
                        "#97469d",
                        "#873d8c",
                        "#76337c",
                    ],
                },
                white: "var(--secondary-color)",
                defaultGradient: {
                    from: getCssVar("--highlight-color"),
                    to: getCssVar("--highlight-alt-color"),
                    deg: 120,
                },
                fontFamily: getCssVar("--font-family"),
                fontSizes: {
                    xs: "1.2rem",
                    sm: "1.4rem",
                    md: "1.6rem",
                    lg: "1.8rem",
                    xl: "3rem",
                },
                primaryColor: "geoHighlight",
                components: {
                    InputWrapper: {
                        styles: {
                            required: {
                                color: "var(--highlight-color)",
                            },
                            error: {
                                color: "var(--error-color)",
                            },
                            label: {
                                fontSize: "var(--font-size-sm)",
                            },
                        },
                    },
                    Input: {
                        styles: {
                            input: {
                                "&[data-invalid]": {
                                    color: "var(--error-color)",
                                    border: "1px solid var(--error-color)",
                                },
                                border: "1px solid var(--border-color)",
                                color: "var(--text-color)",
                                "&::placeholder": {
                                    color: "var(--text-color)",
                                    opacity: 0.3,
                                },
                            },
                        },
                    },
                    TextInput: {
                        variants: {
                            twoColumn: () => ({
                                root: {
                                    width: "100%"
                                },
                                input: {
                                    backgroundColor: "var(--primary-color)",
                                    borderRadius: "var(--border-radius-sm)",
                                    height: "3rem",
                                }
                            }),
                            primaryBg: () => ({
                                input: {
                                    backgroundColor: "var(--primary-color)",
                                    borderRadius: "var(--border-radius-sm)",
                                    height: "3rem"
                                }
                            })
                        }
                    },
                    FileInput: {
                        styles: {
                            placeholder: {
                                color: "var(--highlight-color)",
                            },
                        },
                    },
                    Switch: {
                        styles: {
                            thumb: {
                                border: "1px solid var(--border-color)",
                                cursor: "pointer",
                            },
                            track: {
                                border: "1px solid var(--border-color)",
                            },
                        },
                    },
                    Tooltip: {
                        styles: {
                            tooltip: {
                                color: "var(--text-color)",
                                backgroundColor: "var(--primary-color)",
                                border: "1px solid var(--border-color)",
                            },
                        },
                    },
                    Button: {
                        variants: {
                            confirm: (theme) => ({
                                root: {
                                    backgroundColor: "var(--primary-color)",
                                    color: "var(--text-color)",
                                    border: "1px solid var(--highlight-color)",
                                    fontWeight: "500",
                                    ...theme.fn.hover({
                                        backgroundColor:
                                            "var(--secondary-color)",
                                    }),
                                },
                                label: {
                                    gap: "0.5rem",
                                }
                            }),
                            cancel: (theme) => ({
                                root: {
                                    backgroundColor: "var(--primary-color)",
                                    color: "var(--text-color)",
                                    border: "1px solid var(--border-color)",
                                    fontWeight: "500",
                                    ...theme.fn.hover({
                                        backgroundColor:
                                            "var(--secondary-color)",
                                    }),
                                },
                            }),
                        },
                    },
                    Checkbox: {
                        variants: {
                            primary: () => ({
                                input: {
                                    background: "var(--primary-color)",
                                    border: "0.1rem solid var(--border-color)",
                                },
                            }),
                        },
                        styles: {
                            label: {
                                cursor: "pointer",
                                fontSize: "var(--font-size-sm)",
                            },
                            input: {
                                cursor: "pointer",
                            },
                        },
                    },
                    Menu: {
                        styles: {
                            dropdown: {
                                backgroundColor: "var(--primary-color)",
                            },
                            item: {
                                backgroundColor: "var(--primary-color)",
                            },
                        },
                    },
                    Mark: {
                        styles: {
                            root: {
                                backgroundColor:
                                    "var(--text-result-highlight-color)",
                            },
                        },
                    },
                    Text: {
                        defaultProps: () => ({
                            color: "var(--text-color)",
                        }),
                    },
                    ScrollArea: {
                        styles: {
                            thumb: {
                                backgroundColor: "var(--highlight-color-50)",
                            },
                            scrollbar: {
                                backgroundColor: "var(--primary-color)",
                            },
                        },
                    },
                    Tabs: {
                        styles: {
                            tab: {
                                "&[data-active]": {
                                    color: "var(--text-color)",
                                },
                            },
                        },
                        variants: {
                            userPreferences: () => ({
                                panel: {
                                    paddingTop: "4rem",
                                    borderLeft: "0.1rem solid var(--border-color) !important",
                                    paddingLeft: "2.5rem !important",
                                    marginLeft: "1rem"
                                },
                                tab: {
                                    marginTop: "0.5rem",
                                    width: "15rem",
                                    borderRadius: "var(--border-radius-sm)",
                                    borderLeft: "0.2rem solid transparent",
                                    "&[data-active]": {
                                        borderLeft: "0.2rem solid var(--highlight-color)",
                                        background: "var(--secondary-color)",
                                        borderRadius: "var(--border-right-radius-sm) ",
                                    },
                                    ":hover": {
                                        background: "var(--secondary-color)"
                                    },
                                },
                                tabLabel: {
                                    fontSize: "1.6rem",
                                    marginLeft: "1rem",
                                }
                            })
                        }
                    },
                },
            }}
        >
            {children}
        </MantineProvider>
    );
};

export default ThemeProvider;
