import React, { useEffect, useState } from "react";
import classes from "../AccountModal.module.css";
import { Collapse } from "@mantine/core";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { AccessManagementUserBase } from "types/auth";
import cx from "classnames"
import Toggle from "components/_Library/Inputs/Toggle/Toggle";
import { getCookie, parseCookie, setCookie } from "utils/Cookies";
import { ADVERTISING_COOKIE_DESCRIPTION, ANALYTICS_COOKIE_DESCRIPTION, COOKIE_SETTINGS_COOKIE_NAME, CookieSettings, FUNCTIONALITY_COOKIE_DESCRIPTION, HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME, NECESSARY_COOKIE_DESCRIPTION } from "components/App/Permissions/CookieBanner/CookieBanner";
import { useSetState } from "@mantine/hooks";
import { useUpdateUserPreferencesMutation } from "crud/hooks/users";

interface OwnProps {
    user: AccessManagementUserBase | undefined
}

type PrivacyTabProps = OwnProps;

enum NotificationOption {
    marketing_emails,
    event_notifications
}

export type NotificationOptions = keyof typeof NotificationOption;
export type NotificationSettings = { [key in NotificationOptions]: boolean };

const PrivacyTab: React.FC<PrivacyTabProps> = ({ user }) => {

    const [cookieSettingsOpened, setCookieSettingsOpen ] = useState(false);
    const [notificationSettingsOpened, setNotificationSettingsOpen] = useState(false);

    const [cookieSettings, setCookieSettings] = useSetState<CookieSettings>({
        Necessary: true,
        Analytics: false,
        Functionality: false,
        Advertising: false,
    })
    const [notificationSettings, setNotificationSettings] = useSetState({
        marketing_emails: true,
        event_notifications: true
    })
    
    useEffect(() => {
        let cookieSettingsStr = getCookie(COOKIE_SETTINGS_COOKIE_NAME);
        if (cookieSettingsStr) {
            let settings = parseCookie(cookieSettingsStr);
            updateCookieSettings(settings);
        }
        if (user) {
            setNotificationSettings({
                event_notifications:  user.event_notifications,
                marketing_emails:  user.marketing_emails
            })
        }
        //(This is being used as componentDidMount, doesn't need dependencies.)
        // eslint-disable-next-line 
    }, [])

    const updateCookieSettings = (
        update: Partial<CookieSettings>,
    ) => {        
        setCookie(
            COOKIE_SETTINGS_COOKIE_NAME,
            `Analytics:${update.Analytics ?? cookieSettings.Analytics},Advertising:${update.Advertising ?? cookieSettings.Advertising},Functionality:${update.Functionality ?? cookieSettings.Functionality}`,
            182,
        );
        setCookie(
            HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
            `Analytics:${update.Analytics ?? cookieSettings.Analytics},Advertising:${update.Advertising ?? cookieSettings.Advertising},Functionality:${update.Functionality ?? cookieSettings.Functionality}`,
            182,
        );
        setCookieSettings({
            Necessary: true,
            Functionality: update.Functionality ?? cookieSettings.Functionality,
            Advertising: update.Advertising ?? cookieSettings.Advertising,
            Analytics: update.Analytics ?? cookieSettings.Analytics
        })
    };

    const createUpdateUserPreferencesMutation = useUpdateUserPreferencesMutation();

    const updateNotificationSettings = async (
        update: Partial<NotificationSettings>,
    ) => {   
        
        const newNotificationSettings = {
            event_notifications: update.event_notifications ?? notificationSettings.event_notifications,
            marketing_emails: update.marketing_emails ?? notificationSettings.marketing_emails
        }
        
        setNotificationSettings(newNotificationSettings)

        const updatedUserPreferences = 
            await createUpdateUserPreferencesMutation.mutateAsync({
                ...newNotificationSettings
            })
        return updatedUserPreferences
    };

    return (
        <div className={classes.PanelContainer}>
            <div className={cx(classes.ContentContainer, classes.SettingsContentContainer)}>
                <div className={cx(classes.ContainerHeader, classes.SettingsContainerHeader)} onClick={() => setCookieSettingsOpen(!cookieSettingsOpened)}>
                    Cookies
                    <Icon path={cookieSettingsOpened? mdiChevronDown : mdiChevronRight} size={1.5}/>
                </div>
                <Collapse in={cookieSettingsOpened}>
                    <div className={classes.SpecificCookie}>
                        <p className={classes.Subheader}>Essential Cookies</p>
                        <p className={classes.CookieDesc}>
                            {NECESSARY_COOKIE_DESCRIPTION}
                        </p>
                    </div>
                    <div className={classes.SpecificCookie}>
                        <div className={classes.CookieHeader}>
                            <p className={classes.Subheader}>Analytics Cookies</p>
                            <Toggle onClick={() => {
                                    updateCookieSettings({
                                        Analytics: !cookieSettings.Analytics,
                                    })
                                }} active={cookieSettings.Analytics}/>
                        </div>
                        <p className={classes.CookieDesc}>
                            {ANALYTICS_COOKIE_DESCRIPTION}
                        </p>
                    </div>
                    <div className={classes.SpecificCookie}>
                        <div className={classes.CookieHeader}>
                            <p className={classes.Subheader}>Functionality Cookies</p>
                            <Toggle onClick={()=>{
                                updateCookieSettings({
                                    Functionality: !cookieSettings.Functionality,
                                })
                            }} active={cookieSettings.Functionality}/>
                        </div>
                        <p className={classes.CookieDesc}>
                            {FUNCTIONALITY_COOKIE_DESCRIPTION}
                        </p>
                    </div>
                    <div className={classes.SpecificCookie}>
                        <div className={classes.CookieHeader}>
                            <p className={classes.Subheader}>Advertising Cookies</p>
                            <Toggle onClick={()=>{
                                updateCookieSettings({
                                    Advertising: !cookieSettings.Advertising,
                                })
                            }} active={cookieSettings.Advertising}/>
                        </div>
                        <p className={classes.CookieDesc}>
                            {ADVERTISING_COOKIE_DESCRIPTION}
                        </p>
                    </div>
                </Collapse>
            </div>

            <div className={cx(classes.ContentContainer ,classes.SettingsContentContainer)}>
                    <div className={cx(classes.ContainerHeader ,classes.SettingsContainerHeader)} onClick={() => setNotificationSettingsOpen(!notificationSettingsOpened)}>
                        Email Notifications
                        <Icon path={notificationSettingsOpened ? mdiChevronDown : mdiChevronRight} size={1.5}/>
                    </div>
                    <Collapse in={notificationSettingsOpened}>
                        <div className={classes.SpecificCookie}>
                            <p className={classes.Subheader}>Service Updates (Always On)</p>
                            <p className={classes.CookieDesc}>
                                Keep up-to-date with essential information about app performance, maintenance, and system improvements.
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.Subheader}>Marketing Updates</p>
                                <Toggle onClick={() => {
                                    updateNotificationSettings({
                                        marketing_emails: !notificationSettings.marketing_emails
                                    })
                                }} active={notificationSettings.marketing_emails}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                Receive marketing communications that keep you informed about the latest news, promotions, and updates from McKenzie Intelligence Services.
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.Subheader}>Event Notifications</p>
                                <Toggle onClick={() => {
                                    updateNotificationSettings({
                                        event_notifications: !notificationSettings.event_notifications
                                    })
                                }} active={notificationSettings.event_notifications}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                Stay informed and get notified for new events as they're published as well as updates for existing events.
                            </p>
                        </div>
                    </Collapse>
                </div>
        </div>
    )
};

export default PrivacyTab;