/**
 * Holds the majority of the map interactivity logic.
 * Passes props to map components for the actual rendering.
 *
 */

import React, { Component, ReactElement, ReactNode } from "react";

import { MapRef, WebMercatorViewport } from "react-map-gl";
import cx from "classnames";

import { connect } from "react-redux";

import { RootState } from "store/store";
import {
    LayersConfig,
    MapType,
    ReportState,
    ReportTimelineSchemaBase,
} from "store/report/reportTypes";
import { BasemapType, LayerInfo } from "store/report/reportTypes";
import { MarkerConfig } from "store/insights/insightsTypes";
import GeocoderWrapper from "../Controls/GeocoderWrapper";
import Timeline from "../../../Timeline/Timeline";
import LayoutSlider from "assets/icons/layouts/LayoutSlider.svg?react";
import LayoutDual from "assets/icons/layouts/LayoutDual.svg?react";
import { mdiSquareOutline, mdiTapeMeasure, mdiVectorRectangle } from "@mdi/js";
import ActionDropdown from "../../../../../../_Library/Dropdown/ActionDropdown";
import containerClasses from "./MapContainer.module.css";
import dropdownClasses from "components/_Library/Dropdown/ActionDropdown.module.css";

import classes from "./MapContainer.module.css";
import MapMenu from "../../MapMenu/MapMenu";

import { MapEvent } from "react-map-gl/dist/es5/components/interactive-map";
import MapLayers, { MapSearchMarker } from "../MapLayers/DataLayers";
import {
    InsightsDonutMarker,
    InsightsLayers,
} from "../MapLayers/InsightsLayers";
import MapInteractionContainer, {
    InteractionModeMapProps,
} from "../MapInteractions/InteractionModeContainer/InteractionModeContainer";
import {
    setBasemap,
    setCenter,
    setInteractionMode,
    setMapType,
} from "store/report/reportActions";
import { RefActionTypes } from "store/ref/refTypes";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { setRef } from "store/ref/refActions";
import { setViewport } from "store/report/reportActions";
import DragAndDrop from "components/Pages/Report/DragAndDrop/DragAndDrop";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";
import {
    removeInsightsData,
    setContractIdFilter,
    setSelectedPortfolio,
} from "../../../../../../../store/insights/insightsActions";
import Icon from "@mdi/react";
import ReactTooltip from "react-tooltip";
import { GEOUserWithPortfolios } from "types/auth";
import { RangeSelect } from "components/_Library/RangeSelect/RangeSelect";
import { UseQueryResult } from "@tanstack/react-query";
import { ReportMapSchema } from "crud/reportMapsCRUD";
import { Button, LoadingOverlay } from "@mantine/core";
import MegaMap from "../MegaMap/MegaMap";
import { constructWKTBBox, deconstructWKTBBox } from "utils/Coordinates";
import { LngLatBounds } from "mapbox-gl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAnalytics, {
    withAnalyticsProps,
} from "components/_Library/HOC/withAnalytics";
import { IconCopy } from "@tabler/icons-react";
import { setAlert } from "store/system/systemActions";
import withMeAccess, {
    WithUserMeAccessProps,
} from "components/_Library/HOC/withMeAccessHOC";

interface OwnProps {
    toggleDownloadModal: () => void;
    mapData: ReportMapSchema | undefined;
    isMapLoading: boolean;
    isLayersConfigLoading: boolean;
    timelineQuery: UseQueryResult<ReportTimelineSchemaBase, Error>;
    hasReportId: boolean;
}

interface DispatchProps {
    setRef: typeof setRef;
    setViewport: typeof setViewport;
    removeInsightsLayer: typeof removeInsightsData;
    setSelectedPortfolio: typeof setSelectedPortfolio;
    setInteractionMode: typeof setInteractionMode;
    setMapType: typeof setMapType;
    setContractIdFilter: typeof setContractIdFilter;
    setCenter: typeof setCenter;
    setBasemap: typeof setBasemap;
    setAlert: typeof setAlert;
}

interface StateProps {
    layerFilters: ReportState["layerFilters"];
    layersConfig: ReportState["layersConfig"];
    // geocoderContainerRef: RefObject<HTMLDivElement>;
    interactiveLayerIds: string[];
    basemaps: [BasemapType, BasemapType];
    basemapOptions: { [key in BasemapType]: string };
    insightsMarker: MarkerConfig | null;
    mapMarkerLocation: null | [number, number];
    locationLabels: boolean;
    interactionMode: string;
    mapboxToken: string;
    user: GEOUserWithPortfolios | null;
    policyData: {
        data: Record<string, any>[];
        stats: {
            total_estimated_exposure: number;
            total_exposed_policies: number;
        };
    } | null;
    currentTimelineDate?: number;
    isPreview: boolean;
}

type MapContainerProps = OwnProps &
    DispatchProps &
    StateProps &
    RouteComponentProps &
    withAnalyticsProps &
    WithUserMeAccessProps;

// Used for populating the layerSelectPopup

interface PopupInfo {
    latitude: number;
    longitude: number;
}

export interface LayerSelectPopupInfo extends PopupInfo {
    layers: LayerInfo[];
}

export interface MapContextMenuInfo extends PopupInfo {
    display: boolean;
    mapRef: React.RefObject<MapRef>;
}

export interface MeasurePopupInfo extends PopupInfo {
    measurement: number | null;
}

interface MapContainerState {
    currentWidth: number;
    dragAndDropActive: boolean;
    expandedControlIndex: number; // index of either ruler, sliders or map views
}

export interface MapProps {
    mapType: MapType;
    interactiveLayerIds: string[]; // list of hoverable/clickable layers.
    basemaps: string[]; // left/right map styling (sat/light/dark)
    mapboxToken: string;
    layers: ReactElement[];
    layersConfig?: LayersConfig;
    handleMapOnHover: (event: MapEvent, mapRef?: MapRef) => void;
    handleMapOnClick: (event: MapEvent) => void;
    handleGetCursor: (state: any) => string;
    controls: { [key: string]: ReactNode };
    leftMapRef: React.RefObject<MapRef>;
    rightMapRef: React.RefObject<MapRef>;
    popup: ReactNode;
    contextMenu: ReactNode;
    additionalLayers: ReactNode;
    additionalInteractiveLayerIds: string[];
    onContextMenu: (mapSide: React.RefObject<MapRef>, event: MapEvent) => void;
    insightLayers: JSX.Element | null;
    insightsDonutMarker: JSX.Element;
    mapSearchMarker: JSX.Element;
}

class MapContainer extends Component<MapContainerProps, MapContainerState> {
    state: MapContainerState = {
        currentWidth: 0,
        dragAndDropActive: false,
        expandedControlIndex: -1,
    };

    // Map ref used in geocoder component to connected to a map.
    // used to connect external layer data with features using setFeatureState
    private leftMapRef: React.RefObject<MapRef>;
    private rightMapRef: React.RefObject<MapRef>;
    private mapContainerRef: React.RefObject<HTMLDivElement>;

    constructor(props: MapContainerProps) {
        super(props);
        this.leftMapRef = React.createRef();
        this.rightMapRef = React.createRef();
        this.mapContainerRef = React.createRef();

        this.props.setRef({ refName: "mapRef", ref: this.leftMapRef });
    }

    componentDidMount() {
        this.setWidth();
        this.props.removeInsightsLayer();
        window.addEventListener("resize", this.setWidth);

        const currentTheme = document
            .getElementsByTagName("html")[0]
            .getAttribute("data-theme");

        this.props.setBasemap({
            mapIndex: 1,
            basemap: currentTheme as BasemapType,
        });

        if (this.props.mapData) {
            if (this.props.mapData.bbox) {
                const { north, south, east, west } = deconstructWKTBBox(
                    this.props.mapData.bbox,
                );

                const width = document.getElementById("dashboard")!.clientWidth;
                const height =
                    document.getElementById("dashboard")!.clientHeight;

                const webMercatorViewport = new WebMercatorViewport({
                    width,
                    height,
                });

                let { zoom, latitude, longitude } =
                    webMercatorViewport.fitBounds([
                        [west, south],
                        [east, north],
                    ]);

                this.props.setCenter({
                    latitude,
                    longitude,
                    zoom,
                    duration: 500,
                });
            } else {
                let {
                    initial_lat: latitude,
                    initial_long: longitude,
                    initial_zoom: zoom,
                } = this.props.mapData;

                this.props.setCenter({
                    latitude,
                    longitude,
                    zoom,
                    duration: 500,
                });
            }
        }
    }
    componentWillUnmount() {
        this.props.setSelectedPortfolio({
            selectedPortfolio: null,
        });
        window.removeEventListener("resize", this.setWidth);
    }

    componentDidUpdate(prevProps: MapContainerProps) {
        if (prevProps.interactionMode !== this.props.interactionMode) {
            this.setControlIndex(-1);
        }
        if (
            this.props.mapData &&
            (prevProps.isMapLoading !== this.props.isMapLoading ||
                this.props.mapData !== prevProps.mapData)
        ) {
            if (this.props.mapData.bbox) {
                const { north, south, east, west } = deconstructWKTBBox(
                    this.props.mapData.bbox,
                );

                const width = document.getElementById("dashboard")!.clientWidth;
                const height =
                    document.getElementById("dashboard")!.clientHeight;

                const webMercatorViewport = new WebMercatorViewport({
                    width,
                    height,
                });

                let { zoom, latitude, longitude } =
                    webMercatorViewport.fitBounds([
                        [west, south],
                        [east, north],
                    ]);

                this.props.setCenter({
                    latitude,
                    longitude,
                    zoom,
                    duration: 2000,
                });
            } else {
                let {
                    initial_lat: latitude,
                    initial_long: longitude,
                    initial_zoom: zoom,
                } = this.props.mapData;
                this.props.setCenter({
                    latitude,
                    longitude,
                    zoom,
                    duration: 2000,
                });
            }
        }
    }

    setControlIndex(index: number) {
        // If index is already expanded, collapse it
        if (this.state.expandedControlIndex === index) {
            this.setState({ ...this.state, expandedControlIndex: -1 });
        } else {
            this.setState({ ...this.state, expandedControlIndex: index });
        }
    }

    controlIndexIsActive(index: number) {
        return this.state.expandedControlIndex === index;
    }

    // -- MAP
    setWidth = () => {
        if (this.mapContainerRef.current) {
            this.setState({
                currentWidth: this.mapContainerRef.current?.offsetWidth || 800,
            });
        }
    };

    setDragAndDropState = (active: boolean) => {
        this.setState({
            dragAndDropActive: active,
        });
    };

    toggleMapInteraction = (interactionMode: string) => {
        if (this.props.interactionMode === interactionMode) {
            this.props.setInteractionMode("standard");
            this.setControlIndex(-1);
        } else {
            if (interactionMode === "measure-distance") {
                this.setControlIndex(0);
                this.props.setInteractionMode("measure-distance");
                this.props.analytics.trackUserEventWithCurrentEvent({
                    name: "measure_tool_clicked",
                    payload: {
                        type: "measure_distance",
                        status: "start",
                    },
                });
            } else if (interactionMode === "measure-area") {
                this.setControlIndex(1);
                this.props.setInteractionMode("measure-area");
                this.props.analytics.trackUserEventWithCurrentEvent({
                    name: "measure_tool_clicked",
                    payload: {
                        type: "measure_area",
                        status: "start",
                    },
                });
            } else {
                this.setControlIndex(-1);
            }
        }
    };

    renderCurrentLayoutIcon = (): { renderElementFn: () => ReactElement } => {
        switch (this.props.layersConfig.mapType) {
            case "single":
                return {
                    renderElementFn: () => (
                        <span onClick={() => this.props.setMapType("single")}>
                            <Icon
                                path={mdiSquareOutline}
                                size={1.5}
                                className={dropdownClasses.ToolBarIcon}
                            />
                        </span>
                    ),
                };
            case "dual":
                return {
                    renderElementFn: () => (
                        <LayoutDual
                            className={dropdownClasses.ToolBarIcon}
                            onClick={() => this.props.setMapType("dual")}
                        />
                    ),
                };
            case "compare":
                return {
                    renderElementFn: () => (
                        <LayoutSlider
                            className={dropdownClasses.ToolBarIcon}
                            onClick={() => this.props.setMapType("compare")}
                        />
                    ),
                };
        }
    };

    getMapBboxCopyText = (): string => {
        if (!this.rightMapRef.current) return "";
        const bounds: LngLatBounds = this.rightMapRef.current
            ?.getMap()
            ?.getBounds();

        return constructWKTBBox(
            bounds.getNorth().toFixed(4),
            bounds.getEast().toFixed(4),
            bounds.getSouth().toFixed(4),
            bounds.getWest().toFixed(4),
        );
    };

    renderMap = (): ReactNode => {
        let mapRenderFunction: (props: InteractionModeMapProps) => JSX.Element;

        let basemaps = this.props.basemaps.map(
            (elem) => this.props.basemapOptions[elem],
        );

        let layers: ReactElement[] = [];

        if (this.props.layersConfig) {
            layers = MapLayers(
                this.props.layersConfig,
                this.props.locationLabels,
                this.props.layerFilters,
                this.props.currentTimelineDate,
            );
        }

        // Collate all props for the map component
        let props = {
            mapType: this.props.layersConfig.mapType || "single",
            basemaps,
            interactiveLayerIds: this.props.interactiveLayerIds,
            mapboxToken: this.props.mapboxToken,
            leftMapRef: this.leftMapRef,
            rightMapRef: this.rightMapRef,
            layersConfig: this.props.layersConfig,
            layers: layers,
            insightLayers: !this.props.isLayersConfigLoading ? (
                <InsightsLayers />
            ) : null,
            insightsDonutMarker: (
                <InsightsDonutMarker
                    marker={this.props.insightsMarker}
                    locationLabels={this.props.locationLabels}
                />
            ),
            mapSearchMarker: MapSearchMarker({
                lnglat: this.props.mapMarkerLocation!,
            }),
        };

        mapRenderFunction = ({
            interactions,
            additionalInteractiveLayerIds,
            layers,
            popup,
            contextMenu,
            controls,
        }: InteractionModeMapProps) => {
            return (
                <MegaMap
                    key={"map"}
                    {...interactions}
                    {...props}
                    additionalInteractiveLayerIds={
                        additionalInteractiveLayerIds
                    }
                    contextMenu={contextMenu}
                    popup={popup}
                    controls={controls}
                    additionalLayers={layers}
                />
            );
        };

        const all_estimated_exposure: number[] | undefined = this.props
            .policyData?.data
            ? this.props.policyData?.data.map((data) => data.estimated_exposure)
            : [];

        return (
            <>
                <div
                    className={classes.MapComponentContainer}
                    id={"tourid_MapComponentContainer"}
                >
                    <div
                        ref={this.mapContainerRef}
                        className={cx(classes.HorizontalContainer, {
                            [classes.CaptureCoords]: false,
                        })}
                    >
                        {this.props.userMeAccessQuery?.data
                            ?.has_policy_access &&
                            this.props.policyData?.data && (
                                <RangeSelect
                                    minValue={Math.min(
                                        ...all_estimated_exposure,
                                    )}
                                    maxValue={Math.max(
                                        ...all_estimated_exposure,
                                    )}
                                    title={"Estimated Exposure"}
                                    format
                                    prefix={"$"}
                                    onSubmit={(value: {
                                        min: number;
                                        max: number;
                                    }) => {
                                        let contractIds: number[] | null =
                                            this.props
                                                .policyData!.data.filter(
                                                    (data) =>
                                                        data.estimated_exposure >=
                                                            value.min &&
                                                        data.estimated_exposure <=
                                                            value.max,
                                                )
                                                .map(
                                                    (data) =>
                                                        data.MIS_ContractID,
                                                );

                                        if (!contractIds.length)
                                            contractIds = null;

                                        this.props.setContractIdFilter({
                                            contractIds: contractIds,
                                        });
                                    }}
                                />
                            )}
                        <div
                            className={cx(
                                containerClasses.DropdownListContainer,
                                {
                                    [containerClasses.Hidden]:
                                        this.props.interactionMode !==
                                        "standard",
                                },
                            )}
                            id={"tourid_Toolbar"}
                            style={{
                                zIndex: 3,
                            }}
                        >
                            <div
                                className={
                                    dropdownClasses.ActionDropdownContainer
                                }
                                data-tip="Measure distance"
                                data-for={"DropdownMeasureDistance"}
                                id="tourid_drawLine"
                            >
                                <span
                                    onClick={() => {
                                        this.toggleMapInteraction(
                                            "measure-distance",
                                        );
                                    }}
                                >
                                    <Icon
                                        className={
                                            this.controlIndexIsActive(0)
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon
                                        }
                                        path={mdiTapeMeasure}
                                        size={1.5}
                                    />
                                </span>
                                <ReactTooltip
                                    id={"DropdownMeasureDistance"}
                                    place={"right"}
                                    type={"dark"}
                                    effect={"solid"}
                                />
                            </div>
                            <div
                                className={
                                    dropdownClasses.ActionDropdownContainer
                                }
                                data-tip="Measure area"
                                data-for={"DropdownMeasureArea"}
                                id="tourid_drawPoly"
                            >
                                <span
                                    onClick={() => {
                                        this.toggleMapInteraction(
                                            "measure-area",
                                        );
                                    }}
                                >
                                    <Icon
                                        className={
                                            this.controlIndexIsActive(1)
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon
                                        }
                                        path={mdiVectorRectangle}
                                        size={1.5}
                                    />
                                </span>

                                <ReactTooltip
                                    id={"DropdownMeasureArea"}
                                    place={"right"}
                                    type={"dark"}
                                    effect={"solid"}
                                />
                            </div>
                            <ActionDropdown
                                containerClassName={
                                    this.props.interactionMode !== "standard"
                                        ? dropdownClasses.Hidden
                                        : dropdownClasses.ActionDropdownContainer
                                }
                                expanded={this.controlIndexIsActive(2)}
                                expanderButtonProps={{
                                    ...this.renderCurrentLayoutIcon(),
                                    onClick: () => {
                                        this.setControlIndex(2);
                                        this.props.analytics.trackUserEventWithCurrentEvent(
                                            {
                                                name: "map_layout_clicked",
                                            },
                                        );
                                    },
                                    tooltip: "Map Layout",
                                    tooltipPlacement: "right",
                                    className: this.controlIndexIsActive(2)
                                        ? dropdownClasses.Highlighted
                                        : dropdownClasses.ToolBarIcon,
                                    id: "tourid_MapLayout",
                                }}
                                innerButtonProps={[
                                    {
                                        renderElementFn: () => (
                                            <span
                                                onClick={() => {
                                                    this.props.setMapType(
                                                        "single",
                                                    );
                                                    this.props.analytics.trackUserEventWithCurrentEvent(
                                                        {
                                                            name: "map_layout_changed",
                                                            payload: {
                                                                type: "single",
                                                            },
                                                        },
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    path={mdiSquareOutline}
                                                    size={1.5}
                                                />
                                            </span>
                                        ),
                                        className:
                                            this.props.layersConfig.mapType ===
                                            "single"
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon,
                                        subHeading: "Single",
                                        id: "tourid_MapLayoutSingle",
                                    },
                                    {
                                        renderElementFn: () => (
                                            <LayoutDual
                                                onClick={() => {
                                                    this.props.setMapType(
                                                        "dual",
                                                    );
                                                    this.props.analytics.trackUserEventWithCurrentEvent(
                                                        {
                                                            name: "map_layout_changed",
                                                            payload: {
                                                                type: "dual",
                                                            },
                                                        },
                                                    );
                                                }}
                                            />
                                        ),
                                        className:
                                            this.props.layersConfig.mapType ===
                                            "dual"
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon,
                                        subHeading: "Dual",
                                        id: "tourid_MapLayoutDual",
                                    },
                                    {
                                        renderElementFn: () => (
                                            <LayoutSlider
                                                onClick={() => {
                                                    this.props.setMapType(
                                                        "compare",
                                                    );
                                                    this.props.analytics.trackUserEventWithCurrentEvent(
                                                        {
                                                            name: "map_layout_changed",
                                                            payload: {
                                                                type: "slider",
                                                            },
                                                        },
                                                    );
                                                }}
                                            />
                                        ),
                                        className:
                                            this.props.layersConfig.mapType ===
                                            "compare"
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon,
                                        subHeading: "Slider",
                                        id: "tourid_MapLayoutSlider",
                                    },
                                ]}
                            />
                        </div>
                        <div className={classes.MapContainer}>
                            <LoadingOverlay
                                visible={
                                    this.props.isMapLoading ||
                                    !this.props.hasReportId
                                }
                            />
                            <GeocoderWrapper />
                            <MapInteractionContainer
                                mapContainerWidth={this.state.currentWidth}
                                mapRenderFunction={mapRenderFunction}
                            />
                        </div>
                        <MapMenu
                            isLayersConfigLoading={
                                this.props.isLayersConfigLoading
                            }
                            setDragAndDropState={this.setDragAndDropState}
                            toggleDownloadModal={this.props.toggleDownloadModal}
                        />
                    </div>
                    {this.props.timelineQuery.data && (
                        <Timeline
                            key={this.props.mapData?.id}
                            reportTimeline={this.props.timelineQuery.data}
                        />
                    )}
                </div>
                {this.state.dragAndDropActive && (
                    <DragAndDrop
                        setDragAndDropState={this.setDragAndDropState}
                    />
                )}
                {this.props.isPreview && (
                    <div
                        style={{
                            position: "absolute",
                            top: "1rem",
                            left: "1rem",
                        }}
                    >
                        <Button
                            variant="confirm"
                            onClick={() => {
                                const bbox = this.getMapBboxCopyText();
                                navigator.clipboard.writeText(bbox);
                                this.props.setAlert({
                                    type: "Default",
                                    message: "Bounding Box to clipboard",
                                });
                            }}
                        >
                            <IconCopy size={16} />
                            <p>Copy Bounding Box</p>
                        </Button>
                    </div>
                )}
            </>
        );
    };
    render = () => {
        return this.renderMap();
    };
}

const mapStateToProps = (
    state: RootState,
): Omit<StateProps, "expandedControlIndex"> => {
    return {
        layerFilters: getStoreAtNamespaceKey(state, "report").layerFilters,
        currentTimelineDate: getStoreAtNamespaceKey(state, "report")
            .currentTimelineDate,
        layersConfig: getStoreAtNamespaceKey(state, "report").layersConfig,
        mapboxToken: getStoreAtNamespaceKey(state, "report").mapboxToken,
        interactiveLayerIds: getStoreAtNamespaceKey(state, "report")
            .interactiveLayerIds,
        basemaps: getStoreAtNamespaceKey(state, "report").basemaps,
        basemapOptions: getStoreAtNamespaceKey(state, "report").basemapOptions,
        insightsMarker: getStoreAtNamespaceKey(state, "insights").marker,
        mapMarkerLocation: getStoreAtNamespaceKey(state, "report")
            .mapMarkerLocation,
        locationLabels: getStoreAtNamespaceKey(state, "report").locationLabels,
        interactionMode: getStoreAtNamespaceKey(state, "report")
            .interactionMode,
        user: getStoreAtNamespaceKey(state, "user").user,
        policyData: getStoreAtNamespaceKey(state, "insights").policyData,
        isPreview: getStoreAtNamespaceKey(state, "report").isPreview,
    };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, RefActionTypes>,
) => ({
    setRef: bindActionCreators(setRef, dispatch),
    setViewport: bindActionCreators(setViewport, dispatch),
    removeInsightsLayer: bindActionCreators(removeInsightsData, dispatch),
    setSelectedPortfolio: bindActionCreators(setSelectedPortfolio, dispatch),
    setInteractionMode: bindActionCreators(setInteractionMode, dispatch),
    setMapType: bindActionCreators(setMapType, dispatch),
    setContractIdFilter: bindActionCreators(setContractIdFilter, dispatch),
    setCenter: bindActionCreators(setCenter, dispatch),
    setBasemap: bindActionCreators(setBasemap, dispatch),
    setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withAnalytics(withMeAccess(MapContainer))));
